import Exif from "exif-js";
const mixin = {
  data() {
    return {
      registerData: {
        name: "",
        idNumber: "",
        nation: "",
        gender: "",
        birthday: "",
        address: "",
        relation: "",
      },
      // 要上传的图片
      uploadBase64: "",
      // 默认图片
      imgUrl: require("@/assets/img_health/muban2.png"),
      // 遮罩层
      overlaySHow: false,
    };
  },
  methods: {
    /**
     * OCR识别证件信息
     */
    getOCR() {
      this.$healthHttpService
        .post("healthapi/api/search/idcard", { imageContent: this.uploadBase64 })
        .then((res) => {
          if (res.data) {
            let { address, birthday, gender, id, name, nation } = res.data;
            this.registerData.name = name;
            this.registerData.idNumber = id;
            this.registerData.gender = gender;
            this.registerData.nation = nation;
            this.registerData.birthday = birthday;
            this.registerData.address = address;
            this.overlaySHow = false;
          } else {
            this.registerData = {
              name: "",
              idNumber: "",
              nation: "",
              address: "",
              phone1: "",
              birthday: "",
              gender: "",
              cardType: { key: "01", text: "居民身份证" },
            };
            this.$toast.fail("OCR识别未成功，请重新上传");
            this.overlaySHow = false;
          }
        });
    },
    /**
     * 读取用户提交的图片，进行压缩以及旋转
     */
    beforeRead(file) {
      this.overlaySHow = true;
      let Orientation = 0;
      Exif.getData(file, function () {
        Orientation = Exif.getTag(this, "Orientation");
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let that = this;
      reader.onload = function () {
        let img = new Image();
        img.src = this.result;
        // 对图片进行压缩;
        img.onload = function () {
          let imgData = that.compress(this, Orientation);
          // 反显图片
          that.imgUrl = that.uploadBase64 = imgData;
          // 发送图片到接口识别
          that.getOCR();
        };
      };
    },
    /***
     * 对图片进行状态获取以及处理方式
     */
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0;
      const max_step = 3;
      if (img == null) return;
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      let height = img.height;
      let width = img.width;
      let step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == "right") {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      let degree = (step * 90 * Math.PI) / 180;
      let ctx = canvas.getContext("2d");
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0);
          break;
      }
    },
    /***
     * 旋转图片
     */
    compress(img, Orientation) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      //瓦片canvas
      let tCanvas = document.createElement("canvas");
      let tctx = tCanvas.getContext("2d");
      let initSize = img.src.length;
      let width = img.naturalWidth;
      let height = img.naturalHeight;
      //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio;
      if ((ratio = (width * height) / 4000000) > 1) {
        console.log("大于400万像素");
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      //        铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //如果图片像素大于100万则使用瓦片绘制
      let count;
      if ((count = (width * height) / 1000000) > 1) {
        console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
        // 计算每块瓦片的宽和高
        let nw = ~~(width / count);
        let nh = ~~(height / count);
        tCanvas.width = nw;
        tCanvas.height = nh;
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            );
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height);
      }
      //修复ios上传图片的时候 被旋转的问题
      if (Orientation != "" && Orientation != 1) {
        switch (Orientation) {
          case 6: //需要顺时针（向左）90度旋转
            this.rotateImg(img, "right", canvas);
            break;
          case 8: //需要逆时针（向右）90度旋转
            this.rotateImg(img, "left", canvas);
            break;
          case 3: //需要180度旋转
            this.rotateImg(img, "right", canvas); //转两次
            this.rotateImg(img, "right", canvas);
            break;
        }
      }
      //进行最小压缩
      let ndata = canvas.toDataURL("image/jpeg", 0.1);
      console.log("压缩前：" + initSize);
      console.log("压缩后：" + ndata.length);
      console.log(
        "压缩率：" + ~~((100 * (initSize - ndata.length)) / initSize) + "%"
      );
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
      return ndata;
    },
  },
};

export default mixin;
