<!-- 电子健康卡注册(1/2) -->
<template>
  <div class="wrap">
    <van-tabs
      v-model="active"
      swipeable
      color="#1bdc9b"
      title-active-color="#1bdc9b"
      @click="switchTabs"
    >
      <van-tab title="上传身份证照片">
        <!-- ocr识别 -->
        <div class="cardWrap">
          <div class="cardImg positionCenter">
            <van-uploader
              ref="load"
              :preview-image="false"
              :before-read="beforeRead"
            >
              <div :class="{ mohu: !uploadBase64 }">
                <img ref="loadImg" :src="imgUrl" alt="" srcset="" />
              </div>
              <div v-if="!uploadBase64">
                <span class="addText positionCenter">
                  点击上传身份证人像面图片</span
                >
                <span class="addText2 positionCenter">
                  (请确保照片内容清晰有效)</span
                >
              </div>
              <div v-else class="uploadAgain fontWhite">
                点击重新上传身份证人像面
              </div>
            </van-uploader>
          </div>
        </div>
        <div class="cardLoadText" v-if="!uploadBase64">
          <p
            style="
              font-size: 0.4rem;
              font-weight: 500;
              font-family: Source Han Sans CN;
              margin-bottom: 5px;
            "
          >
            照片要求:
          </p>
          <p class="require">照片保持证件边框完整</p>
          <p class="require">照片尽量不要过远拍摄</p>
          <p class="require">文字信息清楚可见，避免照片模糊</p>
        </div>
        <div v-else class="cardData">
          <van-form @submit="onSubmit" label-width="2rem" input-align="right">
            <van-field
              name="name"
              readonl2
              label="姓名"
              right-icon="edit"
              v-model="registerData.name"
              :rules="[{ required: true, message: '就诊人姓名未填写' }]"
            >
            </van-field>
            <van-field
              readonly
              name="gender"
              label="性别"
              v-model="registerData.gender"
            />
            <van-field
              readonly
              name="nation"
              label="民族"
              v-model="registerData.nation"
            />
            <van-field
              readonly
              name="idNumber"
              label="身份证"
              v-model="registerData.idNumber"
            />
            <van-field
              name="address"
              v-model="registerData.address"
              rows="1"
              autosize
              label="地址"
              type="textarea"
              input-align="left"
            />
            <!-- <div style="margin: 16px">
              <van-button round block type="info" native-type="submit"
                >下一步，填写手机号</van-button
              >
            </div> -->
          </van-form>
          <div class="cardLoadButton">
            <van-button
              type="primary"
              size="large"
              class="add-pat"
              @click="handleBind"
            >
              <van-icon name="plus" color="#fff" />
              注册/绑定
            </van-button>
          </div>
        </div>
      </van-tab>
      <van-tab title="手动输入">
        <!-- 手动输入 -->
        <van-form @submit="onSubmit" label-width="2rem">
          <van-field
            v-model="registerData.name"
            name="name"
            label="姓名"
            placeholder="请输入就诊人姓名"
            :rules="[{ required: true }]"
          />
          <van-field
            readonly
            clickable
            name="nation"
            label="民族"
            v-model="registerData.nation"
            is-link
            arrow-direction="down"
            placeholder="请选择民族"
            @click="showPicker = true"
            :rules="[{ required: true }]"
          />
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              title="民族"
              show-toolbar
              :columns="columns"
              is-link
              arrow-direction="down"
              @confirm="onConfirm"
              @cancel="onCancel"
            />
          </van-popup>
          <van-field
            v-model="registerData.cardType.text"
            readonly
            clickable
            is-link
            arrow-direction="down"
            name="cardType"
            label="证件类型"
            @click="showPicker1 = true"
          ></van-field>
          <van-popup v-model="showPicker1" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns1"
              @cancel="showPicker1 = false"
              @confirm="onConfirm1"
              :default-index="0"
            />
          </van-popup>
          <van-field
            ref="idNumber"
            v-model="registerData.idNumber"
            name="idNumber"
            label="证件号码"
            placeholder="请输入就诊人证件号码"
            :rules="[{ required: true }]"
            maxlength="18"
            @touchstart.stop="
              show = true;
              show1 = false;
            "
          />
          <van-field
            v-model="registerData.birthday"
            name="name"
            label="出生年月"
            readonly
            clickable
            placeholder="请输入就诊人出生年月"
            :rules="[{ required: true }]"
            v-if="registerData.cardType.key != '01'"
            @click="birthdayShow = true"
          />
          <van-popup v-model="birthdayShow" round position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              @cancel="birthdayShow = false"
              @confirm="onConfirm2"
              :min-date="minDate"
              :max-date="maxDate"
            />
          </van-popup>
          <!-- <van-calendar v-model="birthdayShow" :min-date="new Date(1900,1,1,0,0)" @confirm="onConfirm2" /> -->
          <van-field
            v-model="registerData.gender"
            name="name"
            label="性别"
            is-link
            readonly
            clickable
            arrow-direction="down"
            placeholder="请选择就诊人性别"
            :rules="[{ required: true }]"
            @click="showPicker3 = true"
            v-if="registerData.cardType.key != '01'"
          />
          <van-popup v-model="showPicker3" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns3"
              @cancel="showPicker3 = false"
              @confirm="onConfirm3"
              :default-index="0"
            />
          </van-popup>
          <van-field
            name="address"
            v-model="registerData.address"
            rows="1"
            autosize
            label="现住址"
            type="textarea"
            :rules="[{ required: true }]"
            input-align="left"
            placeholder="请输入现住址"
          />

          <van-field
            type="tel"
            v-model="registerData.phone1"
            ref="phone1"
            name="phone"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[{ required: true }]"
            maxlength="18"
            @touchstart.stop="
              show1 = true;
              show = false;
            "
          />
          <!-- <van-number-keyboard
            :show="show1"
            v-model="registerData.phone1"
            close-button-text="完成"
            @blur="show1 = false"
          /> -->
          <!-- <van-button type="primary" native-type="submit"
            >下一步，填写手机号</van-button
          > -->

          <div class="cardLoadText" v-if="!uploadBase64">
            <p
              style="
                font-size: 0.4rem;
                font-weight: 500;
                font-family: Source Han Sans CN;
                margin-bottom: 5px;
              "
            >
              说明:
            </p>
            <p class="require">查询申请人信息，如无卡则直接注册电子健康卡；</p>
            <p class="require">有卡直接绑定电子健康卡。</p>
          </div>
          <div class="cardLoadButton" v-if="!uploadBase64">
            <van-button
              type="primary"
              size="large"
              class="add-pat"
              @submit="onSubmit"
              :loading="loading"
              :disabled="loading"
            >
              <van-icon name="plus" color="#fff" />
              注册/绑定
            </van-button>
          </div>
        </van-form>
      </van-tab>
    </van-tabs>

    <div v-if="!uploadBase64" style="color: #999; font-size: 0.4rem">
      一键授权，已有健康卡用户<span style="color: #1bdc9b" @click="addCard"
        >直接绑定>></span
      >
    </div>
    <div style="color: #999; font-size: 0.4rem">
      身份验证失败<span style="color: #1bdc9b" @click="handleAppeal"
        >申诉？</span
      >
    </div>
    <!-- 遮罩层 -->
    <van-overlay :show="overlaySHow" @click="overlaySHow = false">
      <van-loading type="spinner" color="#1989fa">图片处理中...</van-loading>
    </van-overlay>

    <van-overlay :show="overlayState">
      <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
    </van-overlay>

    <!-- 对话框 -->
    <van-dialog
      v-model="showDialog1"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #default>
        <div class="a1">
          <div class="a2">
            <p class="a21">
              <img src="@/assets/img/ucenter/lingdang1.png" alt="" />
            </p>
          </div>
          <h3 class="dialog-message">认证失败</h3>
          <p class="dialog-message1">
            {{ diologTips }}
          </p>
          <van-button
            type="primary"
            class="confirm-btn"
            @click="showDialog1 = false"
            >我知道了</van-button
          >
        </div>
        <div class="a31">
          <img
            src="@/assets/img/ucenter/20.png"
            alt=""
            @click="showDialog1 = false"
          />
        </div>
      </template>
    </van-dialog>
    <van-dialog
      v-model="showDialog2"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #default>
        <div class="a1">
          <div class="a2" style="top: -75%">
            <p class="a21">
              <img src="@/assets/img/ucenter/lingdang2.png" alt="" />
            </p>
          </div>
          <h3 class="dialog-message">确认就诊人信息</h3>
          <van-form @submit="onSubmit">
            <p class="dialog-message1" style="text-align: left">
              <label class="input-label">姓名：</label>
              {{ registerData.name }}
            </p>
            <p class="dialog-message1" style="text-align: left">
              <label class="input-label">性别：</label>
              {{ registerData.gender }}
            </p>
            <p class="dialog-message1" style="text-align: left">
              <label class="input-label">证件号：</label>
              {{ registerData.idNumber }}
            </p>
            <p class="dialog-message1" style="text-align: left">
              <label class="input-label">手机号：</label>
              <input
                type="tel"
                class="input-tel"
                v-model="registerData.phone1"
                placeholder="请输入手机号"
              />
            </p>
            <p class="dialog-message1" style="text-align: left; color: #f49e0e">
              <img
                src="@/assets/img_health/warning.png"
                class="warning"
                alt=""
              />
              请仔细检查姓名、身份证号是否输入正确，以免影响您的就诊
            </p>
            <van-button
              type="primary"
              class="cancle-btn"
              @click="showDialog2 = false"
              >取消</van-button
            >
            <van-button type="primary" class="confirm-btn" :loading="loading"
              >确认</van-button
            >
          </van-form>
        </div>
        <div class="a31">
          <img
            src="@/assets/img/ucenter/20.png"
            alt=""
            @click="showDialog2 = false"
          />
        </div>
      </template>
    </van-dialog>
  </div>
</template>

<script>
import mixin from "./addCardOne";
export default {
  name: "addCardOne",
  mixins: [mixin],
  data() {
    return {
      currentDate: new Date(),
      minDate: new Date(1900, 1, 1, 0, 0),
      maxDate: new Date(),
      // 默认tabs选中
      active: 1,
      // 身份证号码输入键盘弹出
      show: false,
      // 手机号码输入键盘弹出
      show1: false,
      // 选择器弹出
      showPicker: false,
      // 民族数据
      columns: [
        "汉族",
        "蒙古族",
        "回族",
        "藏族",
        "维吾尔族",
        "苗族",
        "彝族",
        "壮族",
        "布依族",
        "朝鲜族",
        "满族",
        "侗族",
        "瑶族",
        "白族",
        "土家族",
        "哈萨克族 ",
        "傣族",
        "黎族",
        "傈僳族",
        "高山族",
        "畲族",
        "畲族",
        "拉祜族",
        "水族",
        "纳西族",
        "景颇族",
        "柯尔克孜族",
        "土族",
        "达斡尔族",
        "仫佬族",
        "羌族",
        "布朗族",
        "撒拉族",
        "毛南族",
        "仡佬族",
        "锡伯族",
        "阿昌族",
        "普米族",
        "塔吉克族",
        "怒族",
        "乌孜别克族",
        "鄂温克族",
        "德昂族",
        "保安族",
        "裕固族",
        "京族",
        "塔塔尔族",
        "独龙族",
        "鄂伦春族",
        "赫哲族",
        "门巴族",
        "珞巴族",
        "基诺族",
        "其它",
        "外国血统",
        "穿青人",
      ],
      // 健康平台返回标识符 0：新用户 -1
      healthCode: "",

      // 根据授权码查出的用户信息
      healthUserData: {},
      overlayState: false,

      // 绑卡须知对话框
      showDialog: false,
      // 扫码绑定对话框
      showDialog1: false,
      showDialog2: false,
      //提示框里面的文字
      diologTips: "",
      showPicker1: false,
      showPicker3: false,
      birthdayShow: false,
      registerData: {
        name: "",
        idNumber: "",
        nation: "",
        address: "",
        phone1: "",
        birthday: "",
        gender: "",
        cardType: { key: "01", text: "居民身份证" },
      },
      columns1: [
        { key: "01", text: "居民身份证" },
        { key: "02", text: "居民户口簿" },
        { key: "03", text: "护照" },
        { key: "04", text: "军官证" },
        { key: "05", text: "驾驶证" },
        { key: "06", text: "港澳居民来往内地通行证" },
        { key: "07", text: "台湾居民来往内地通行证" },
        { key: "08", text: " 出生医学证明" },
        { key: "15", text: "外国人永久居住证" },
        { key: "99", text: "其他法定有效证件" },
      ],
      columns3: [
        {
          key: "0",
          text: "男",
        },
        {
          key: "1",
          text: "女",
        },
      ],
      loading: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === "addCardThree") {
      next("/");
      return;
    }
    next();
  },
  created() {
    let wechatCode = (this.code = sessionStorage.getItem("wechatCode"));
    if (!wechatCode) {
      this.getUserCode();
    } else {
      if (this.getUrlCode()) {
        if (this.getUrlCode().healthCode) {
          this.healthCode = this.getUrlCode().healthCode; // 截取url用中的healthCode
          if (this.healthCode !== undefined && this.healthCode != 0) {
            this.overlayState = true;
            //直接跨院注册
            this.getCardInfo(this.healthCode);
          }
        }
      }
    }
    this.getCardList();
  },
  methods: {
    /**
     * 获取平台新增卡授权码
     */
    getUserCode() {
      // 缓存里没有说明还没有跳转
      // if (!sessionStorage.getItem("wechatCode")) {
      let wechatCode = "";
      let url = location.href;
      if (url.indexOf("wechatCode") === -1) {
        let redirectUrl = `${domain}index.html%23%2FaddCardOne`;
        window.location.href = `https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirectUrl}`;
      } else {
        // 从url里面取wechatCode
        wechatCode = url.split("?")[1].split("#")[0].split("=")[1];
        // url没有说明还没跳转
        if (wechatCode !== null || wechatCode !== "") {
          sessionStorage.setItem("wechatCode", wechatCode);
        }
      }
      // }
    },

    // 根据healthCode获取卡信息
    getCardInfo(healthCode) {
      if (!healthCode) return;
      this.overlayState = true;
      this.$healthHttpService
        .get("healthapi/api/search/bycode", { healthCode })
        .then((res) => {
          this.overlayState = false;
          if (res.data) {
            // 拿到授权码中的用户信息获取院内患者id进行绑定
            Object.assign(this.healthUserData, res.data);
            this.crossRegister();
          }
        })
        .catch((err) => {
          this.overlayState = false;
          this.$notify({
            type: "warning",
            message: "发生未知错误，绑定失败",
          });
        });
    },

    /**
     * 直接跨院注册
     */
    async crossRegister() {
      try {
        // 跨行绑定流程
        // activePatientId
        let postData = {
          HealthCardId: this.healthUserData.healthCardId,
          name: this.healthUserData.name,
          qrCodeText: this.healthUserData.qrCodeText,
          gender: this.healthUserData.gender,
          nation: this.healthUserData.nation,
          birthday: this.healthUserData.birthday,
          idNumber: this.healthUserData.idNumber,
          idType: this.healthUserData.idType,
          address: this.healthUserData.address,
          citycode: this.healthUserData.citycode,
          phone1: this.healthUserData.phone1,
          openid: sessionStorage.getItem("openid"),
          // patid: this.activePatientId,
        };

        await this.$healthHttpService.post(
          "healthapi/api/health/bycode",
          postData
        );
        let result = await this.$http.get("/api/search/cardlist", {
          userid: sessionStorage.getItem("openid"),
          type: 1,
        });
        result.data.forEach((item) => {
          if (item.idNumber == this.healthUserData.idCard) {
            sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
          }
        });
        this.overlayState = false;
        const backRoute = sessionStorage.getItem("backRoute");
        this.$router.replace(backRoute);
      } catch (error) {
        this.overlayState = false;
        this.$notify({
          type: "warning",
          message: "发生未知错误，绑定失败",
        });
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    /**
     * 保存用户注册信息
     * 跳转到第二步
     */
    onSubmit() {
      let dataIsOK = true;
      // delete this.registerData.relation;
      // for (const item in this.registerData) {
      //   // 判断数据是否都完整
      //   if (this.registerData[item] === '') {
      //     dataIsOK = false;
      //   }
      // }
      if (!sessionStorage.getItem("openid")) {
        this.$toast.fail("没有openid");
        return;
        // dataIsOK = false;
      }
      if (!sessionStorage.getItem("wechatCode")) {
        // dataIsOK = false;
        this.$toast.fail("没有微信授权码");
        return;
      }
      if (dataIsOK) {
        let idNumberReg =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        let idCard = this.registerData.idNumber;
        let formData = { ...this.registerData };
        // 获取性别和出生日期
        if (this.registerData.cardType.key == "01") {
          idCard.slice(16, 17) % 2 === 0
            ? (formData.gender = "女")
            : (formData.gender = "男");
          formData.birthday =
            idCard.slice(6, 10) +
            "-" +
            idCard.slice(10, 12) +
            "-" +
            idCard.slice(12, 14);
          if (!idNumberReg.test(idCard)) {
            this.idNumber = "";
            this.$toast.fail("身份证号码有误");
            this.$refs.idNumber.focus();
            // this.show=true
            return;
          }
        } else {
          formData.gender = this.registerData.gender;
          formData.birthday = new Date(this.registerData.birthday);
        }
        let reg_tel =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
        if (!reg_tel.test(this.registerData.phone1)) {
          this.$notify({ type: "warning", message: "需填写正确的手机号" });
          this.$refs.phone1.focus();
          // this.show1=true
          return;
        }
        formData.phone2 = this.registerData.phone1;

        let healthsCardListData = JSON.parse(
          sessionStorage.getItem("healthsCardListData")
        );
        let healthsCardListRes = healthsCardListData.find(
          (item) => item.idNumber == this.registerData.idNumber
        );
        if (healthsCardListRes != undefined) {
          this.diologTips = "同患者的常用就诊人身份证不可相同，请核实后再添加";
          this.showDialog1 = true;
          return;
        }
        this.loading = true;
        let postData = { ...formData };
        let wechatCode = sessionStorage.getItem("wechatCode"); //微信身份码
        let openid = sessionStorage.getItem("openid"); //用户id
        postData.wechatCode = wechatCode;
        postData.openid = openid;
        (postData.citycode = ""),
          // postData.patid = this.activePatientId;
          (postData.idType = this.registerData.cardType.key);
        delete postData.cardType;
        delete postData.phone;
        this.$healthHttpService
          .post("healthapi/api/health/register", postData)
          .then((res) => {
            this.loading = false;
            if (res.status == 200) {
              this.crossRegisterState = false;
              sessionStorage.removeItem("wechatCode");
              if (res.data.qrCodeText) {
                this.$toast({
                  message: "注册/绑定成功",
                  duration: 500,
                  onClose: () => {
                    sessionStorage.removeItem("userData");
                    // history.go(-1);
                    const backRoute = sessionStorage.getItem("backRoute");
                    this.$router.replace(backRoute);
                  },
                });
              }
            } else {
              this.diologTips = "注册失败，请重新操作";
              this.showDialog1 = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.crossRegisterState = false;
            this.$toast(err.response.data);
            //this.$router.push('/healthCard');
            //this.$router.go(-2);
          });
        // sessionStorage.setItem('userData', JSON.stringify(formData));
        // this.$router.push('/addCardTwo');
      } else {
        this.$toast.fail("数据不完整");
      }
    },
    getCardList() {
      this.$http
        .get(
          "/api/search/cards?userid=" +
            sessionStorage.getItem("openid") +
            "&t=" +
            new Date().getTime()
        )
        .then((res) => {
          if (res.data) {
            // 展示卡列表
            window.sessionStorage.setItem(
              "healthsCardListData",
              JSON.stringify(res.data.healths)
            );
          }
        });
    },
    /**
     * 切换tab页置空注册信息对象，防止数据冲突
     */
    switchTabs() {
      this.registerData = {
        name: "",
        idNumber: "",
        nation: "",
        address: "",
        phone1: "",
        cardType: { key: "01", text: "居民身份证" },
      };
      this.uploadBase64 = "";
      this.imgUrl = require("@/assets/img_health/muban2.png");
    },
    /**
     * 弹出组件确定
     */
    onConfirm(value) {
      this.registerData.nation = value;
      this.showPicker = false;
    },
    onConfirm1(value) {
      this.registerData.cardType = value;
      this.showPicker1 = false;
    },
    onConfirm2(date) {
      this.birthdayShow = false;
      this.registerData.birthday = this.formatDate(date);
    },
    onConfirm3(value) {
      console.log(value);
      this.registerData.gender = value.text;
      this.showPicker3 = false;
    },
    /**
     * 弹出组件取消
     */
    onCancel() {
      this.$toast({
        message: "取消",
        duration: 500,
        onClose: () => {
          this.showPicker = false;
        },
      });
    },
    // 身份验证失败申诉
    handleAppeal() {
      window.location.replace(
        "https://h5-health.tengmed.com/h5/whitelist/checking?from=official_account&hospitalId=32912&channel=0401"
      );
    },
    // 获取url中code
    getUrlCode() {
      var url = location.hash.split("?")[1];
      if (url) {
        let strs = url.split("&");
        var theRequest = new Object();
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
        return theRequest;
      }
    },
    // 点击绑定按钮触发弹框
    handleBind() {
      this.showDialog2 = true;
    },
    addCard() {
      let redirectUrl = `${domain}index.html%23%2FaddCardOne`;
      window.location.replace(
        `https://health.tengmed.com/open/getHealthCardList?redirect_uri=${redirectUrl}`
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (sessionStorage.getItem("openid") == "undefined") {
        this.$toast.fail({
          message: "请先关注河南医学高等专科学校附属医院微信服务号",
          duration: 0,
        });
        this.loading = true;
      }
    });
  },
};
</script>
<style scoped>
.cardWrap {
  width: 9rem;
  height: 5rem;
  border-radius: 0.4rem;
  margin-top: 0.2rem;
  margin: 15px auto;
  background-color: #fff;
  border: 1px dashed #c5c5c7;
  position: relative;
}
.cardImg {
  width: 6rem;
  height: 4rem;
  border-radius: 0.2rem;
  padding: 0.1rem;
  background-color: #fff;
  border-radius: 0.2rem;
  position: absolute;
}
.mohu {
  -webkit-filter: blur(2px);
  opacity: 1;
  /* background-color: rgba(60, 68, 192, 0.2); */
}
.cardImg img {
  width: 5.7rem;
  height: 3rem;
}
.icon-credentials_icon {
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.3);
}
.iconAdd {
  font-size: 1rem;
  position: absolute;
  top: 1.5rem;
  color: rgb(145, 145, 145);
}
.addText {
  position: absolute;
  top: 3.5rem;
  display: inline-block;
  width: 6rem;
  color: rgb(145, 145, 145);
  font-weight: bold;
  font-size: 0.4rem;
}
.addText::before {
  content: "";
  display: inline-block;
  background-image: url("~@/assets/img_health/photo.png");
  background-size: 100% 100%;
  width: 0.5rem;
  height: 0.5rem;
  position: relative;
  top: 3.5px;
}

.addText2 {
  position: absolute;
  top: 4rem;
  display: inline-block;
  width: 6rem;
  color: rgb(145, 145, 145);
  font-weight: bold;
  font-size: 0.4rem;
}

/* .preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0.08rem;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
} */
.cardLoadText,
.cardData {
  width: 100%;
  border-radius: 0.2rem;
  margin-top: 0.2rem;
  text-align: left;
}
.cardData {
  padding: 0;
}
.cardLoadText {
  font-size: 0.27rem;
  line-height: 0.6rem;
  margin-left: 0.5rem;
}
.cardLoadText p {
  color: rgb(130, 130, 130);
}
.uploadAgain {
  width: 100%;
  height: 0.7rem;
  font-size: 0.25rem;
  line-height: 0.7rem;
  background-color: rgba(161, 70, 247, 0.3);
  position: absolute;
  bottom: 0.1rem;
}
/deep/.van-tabs__line {
  width: 15px;
}
.cardLoadText .require:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ff9a4b;
  position: relative;
  top: -2px;
  margin-right: 5px;
}
.add-pat {
  width: 9rem;
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #59fdc5, #00d48b);
  margin-bottom: 0.5rem;
  font-size: 0.4rem;
}

.add-pat .van-icon {
  position: relative;
  top: -1px;
  left: -2px;
}
.wrap {
  background-color: transparent;
}
/deep/.van-tabs__nav {
  background-color: transparent;
}
/deep/.van-tabs__wrap {
  border-bottom: 1px solid #d4d4d4;
}
.cancle-btn {
  width: 40%;
  height: 40px;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.confirm-btn {
  width: 40%;
  height: 40px;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
/deep/.van-dialog__header {
  padding-bottom: 0.5rem;
}
.dialog-message {
  font-size: 0.45rem;
  line-height: 26px;
  padding: 0.1rem 0.8rem;
  text-align: center;
}
/deep/.van-form {
  width: 9rem;
  margin: 15px auto;
}
.van-dialog {
  /* height: 400px; */
  width: 9rem;
  /* background-color: red; */
  background-color: rgba(0, 0, 0, 0);
}

.a1 {
  /* height: 200px; */
  background-color: #fff;
  margin-top: 60px;
  border-radius: 20px;
  margin-bottom: 50px;
}
.a2 {
  width: 80px;
  height: 80px;
  margin: auto;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: -68%;
  right: 0;
}
.a21 {
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  background-color: #dbf5ec;
  margin: auto;
  margin-top: 6px;
}
.a31 {
  height: 50px;
}
.a31 img {
  width: 50px;
  height: 50px;
}

.dialog-message {
  font-size: 0.45rem;
  padding-top: 65px;
}
.dialog-message1 {
  font-size: 0.4rem;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: rgba(112, 112, 112, 1);
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.warning {
  width: 0.4rem;
  height: 0.4rem;
}
.input-tel {
  border: 1px solid #afafaf;
  padding: 5px 0;
  text-indent: 10px;
  width: 60%;
}
.input-label {
  width: 2.5rem;
  display: inline-block;
}
.cardLoadButton {
  text-align: center;
}
</style>
